import React from "react";


function About() {
    return(
        <>
        <div className="max-w-2xl mx-auto py-4 px-4 grid items-center grid-cols-1 gap-y-2.5 gap-x-8 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-16 lg:grid-cols-2">
        
        <div className="" >
        <div data-aos="fade-right" data-aos-duration="1200" class="avatar">
							
								<img src="img/slider/avatar1.png" alt="" />
								<span class="skills javascript anim_moveBottom"><img class="svg" src="img/svg/skills/javascript.svg" alt="" /></span>
								<span class="skills wordpress anim_moveBottom"><img class="svg" src="img/svg/skills/wordpress.svg" alt="" /></span>
								<span class="skills bootstrap anim_moveBottom"><img class="svg" src="img/svg/skills/bootstrap.svg" alt="" /></span>
							
			</div>
        </div>
        <div id="about">
            <div data-aos="fade-up" data-aos-duration="1200">
                <h2 className="text-xl font-extrabold tracking-tight text-gray-900 sm:text-2xl">About Me</h2>
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Creative Independent Web Developer & Designer </h2>
            </div>
          
        <div data-aos="fade-up" data-aos-duration="1200">
            <p className="mt-4 text-gray-500">
          Hello! My name is Eric Williams, and I am a Georgia-based Web Designer and Developer with over
           5 years of experience specializing in various Front-End technologies and Web Design applications.
          </p>
        </div>
        <div data-aos="fade-up" data-aos-duration="1200">
          <p className="mt-4 text-gray-500">
          I have a bachelor’s degree in Computer Science from the reputed Albany State University along with a Graduate Certification in Web Design and Development 
          from the University of Denver. Through my academic journey, I applied a strong focus on building my web development and technical support abilities. 
          In addition to my knowledge base, I actively seek out new technologies and stay up-to-date on industry trends and advancements.  </p>
        </div>
        </div>
        
      </div><div className="my_shape2">
              <img className="svg" src="img/svg/paper2.svg" alt="" />
            </div>
    </>
    );
}

export default About;