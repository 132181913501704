import React from "react";


export default function Contact(){
    return(
      <>
   
        <section class="bg-white py-20 px-16 lg:py-[120px] overflow-hidden relative z-10" id="contact">
             
           <div class="container">
              <div class="flex flex-wrap lg:justify-between -mx-4">
                 <div data-aos="fade-right" data-aos-duration="1200" class="w-full lg:w-1/2 xl:w-6/12 px-4">
                    <div class="max-w-[570px] mb-12 lg:mb-0">
                       <h2
                          class="
                          text-black
                          mb-6
                          uppercase
                          font-bold
                          text-[32px]
                          sm:text-[40px]
                          lg:text-[36px]
                          xl:text-[40px]
                          "
                          >
                          GET IN <span className="text-orange">TOUCH</span> 
                       </h2>
                       <p class="text-base text-body-color leading-relaxed mb-9">
                          Interested in working together? Obviously you are if you made it this far. 
                          So, what are you waiting for? Reach out today for more information about my services or book a <span className="text-orange"><a href="https://ericwilliamsjr.hbportal.co/schedule/6295b5902099b20277b4ac18">consultation</a></span> so we can talk soon as possible .
                       </p>
                       <div class="flex mb-8 max-w-[370px] w-full">
                          <div
                             class="
                             max-w-[60px]
                             sm:max-w-[70px]
                             w-full
                             h-[60px]
                             sm:h-[70px]
                             flex
                             items-center
                             justify-center
                             mr-6
                             overflow-hidden
                             bg-primary bg-opacity-5
                             text-black
                             rounded
                             "
                             >
                             <span><i class="fa-brands fa-github"></i></span>
                          </div>
                          <div class="w-full">
                             <h4 class="font-bold text-black text-xl mb-1">Github</h4>
                             <p class="text-base text-body-color">
                             https://github.com/ewill025
                             </p>
                          </div>
                       </div>
                       <div class="flex mb-8 max-w-[370px] w-full">
                          <div
                             class="
                             max-w-[60px]
                             sm:max-w-[70px]
                             w-full
                             h-[60px]
                             sm:h-[70px]
                             flex
                             items-center
                             justify-center
                             mr-6
                             overflow-hidden
                             bg-primary bg-opacity-5
                             text-black
                             rounded
                             "
                             >
                             <span><i class="fa-brands fa-facebook"></i></span>
                          </div>
                          <div class="w-full">
                             <h4 class="font-bold text-black text-xl mb-1">Facebook</h4>
                             <p class="text-base text-body-color"><a href="https://www.facebook.com/ericwilliamsjr11/">Eric Williams</a></p>
                          </div>
                       </div>
                       <div class="flex mb-8 max-w-[370px] w-full">
                          <div
                             class="
                             max-w-[60px]
                             sm:max-w-[70px]
                             w-full
                             h-[60px]
                             sm:h-[70px]
                             flex
                             items-center
                             justify-center
                             mr-6
                             overflow-hidden
                             bg-primary bg-opacity-5
                             text-black
                             rounded
                             "
                             >
                             <span><i class="fa-solid fa-at"></i></span>
                          </div>
                          <div class="w-full">
                             <h4 class="font-bold text-black text-xl mb-1">
                                Email Address
                             </h4>
                             <p class="text-base text-body-color">info@ericwilliamsjr.dev</p>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div data-aos="fade-left" data-aos-duration="1200" class="w-full lg:w-1/2 xl:w-5/12 px-4">
                    <div class="bg-white relative rounded-lg p-8 sm:p-12 shadow-lg">
                    <div class="hb-p-627a6d410877bb000843f58a-2"></div><img alt="honeybook" height="1" width="1" style={{visibility:"hidden"}} src="https://www.honeybook.com/p.png?pid=627a6d410877bb000843f58a"/>
                       <div>
                          <span class="absolute -top-10 -right-9 z-[-1]">
                             <svg
                                width="100"
                                height="100"
                                viewBox="0 0 100 100"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                   fill-rule="evenodd"
                                   clip-rule="evenodd"
                                   d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
                                   fill="#fa5b0f"
                                   />
                             </svg>
                          </span>
                          <span class="absolute -right-10 top-[90px] z-[-1]">
                             <svg
                                width="34"
                                height="134"
                                viewBox="0 0 34 134"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <circle
                                   cx="31.9993"
                                   cy="132"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 132)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="117.333"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 117.333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="102.667"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 102.667)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="88"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 88)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="73.3333"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 73.3333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="45"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 45)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="16"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 16)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="59"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 59)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="30.6666"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 30.6666)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="1.66665"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 1.66665)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="132"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 132)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="117.333"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 117.333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="102.667"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 102.667)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="88"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 88)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="73.3333"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 73.3333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="45"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 45)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="16"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 16)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="59"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 59)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="30.6666"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 30.6666)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="1.66665"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 1.66665)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="132"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 132)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="117.333"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 117.333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="102.667"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 102.667)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="88"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 88)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="73.3333"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 73.3333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="45"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 45)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="16"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 16)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="59"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 59)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="30.6666"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 30.6666)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="1.66665"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 1.66665)"
                                   fill="#000"
                                   />
                             </svg>
                          </span>
                          <span class="absolute -left-7 -bottom-7 z-[-1]">
                             <svg
                                width="107"
                                height="134"
                                viewBox="0 0 107 134"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <circle
                                   cx="104.999"
                                   cy="132"
                                   r="1.66667"
                                   transform="rotate(180 104.999 132)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="104.999"
                                   cy="117.333"
                                   r="1.66667"
                                   transform="rotate(180 104.999 117.333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="104.999"
                                   cy="102.667"
                                   r="1.66667"
                                   transform="rotate(180 104.999 102.667)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="104.999"
                                   cy="88"
                                   r="1.66667"
                                   transform="rotate(180 104.999 88)"
                                   fill="#13C296"
                                   />
                                <circle
                                   cx="104.999"
                                   cy="73.3333"
                                   r="1.66667"
                                   transform="rotate(180 104.999 73.3333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="104.999"
                                   cy="45"
                                   r="1.66667"
                                   transform="rotate(180 104.999 45)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="104.999"
                                   cy="16"
                                   r="1.66667"
                                   transform="rotate(180 104.999 16)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="104.999"
                                   cy="59"
                                   r="1.66667"
                                   transform="rotate(180 104.999 59)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="104.999"
                                   cy="30.6666"
                                   r="1.66667"
                                   transform="rotate(180 104.999 30.6666)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="104.999"
                                   cy="1.66665"
                                   r="1.66667"
                                   transform="rotate(180 104.999 1.66665)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="90.3333"
                                   cy="132"
                                   r="1.66667"
                                   transform="rotate(180 90.3333 132)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="90.3333"
                                   cy="117.333"
                                   r="1.66667"
                                   transform="rotate(180 90.3333 117.333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="90.3333"
                                   cy="102.667"
                                   r="1.66667"
                                   transform="rotate(180 90.3333 102.667)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="90.3333"
                                   cy="88"
                                   r="1.66667"
                                   transform="rotate(180 90.3333 88)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="90.3333"
                                   cy="73.3333"
                                   r="1.66667"
                                   transform="rotate(180 90.3333 73.3333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="90.3333"
                                   cy="45"
                                   r="1.66667"
                                   transform="rotate(180 90.3333 45)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="90.3333"
                                   cy="16"
                                   r="1.66667"
                                   transform="rotate(180 90.3333 16)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="90.3333"
                                   cy="59"
                                   r="1.66667"
                                   transform="rotate(180 90.3333 59)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="90.3333"
                                   cy="30.6666"
                                   r="1.66667"
                                   transform="rotate(180 90.3333 30.6666)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="90.3333"
                                   cy="1.66665"
                                   r="1.66667"
                                   transform="rotate(180 90.3333 1.66665)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="75.6654"
                                   cy="132"
                                   r="1.66667"
                                   transform="rotate(180 75.6654 132)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="132"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 132)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="75.6654"
                                   cy="117.333"
                                   r="1.66667"
                                   transform="rotate(180 75.6654 117.333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="117.333"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 117.333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="75.6654"
                                   cy="102.667"
                                   r="1.66667"
                                   transform="rotate(180 75.6654 102.667)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="102.667"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 102.667)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="75.6654"
                                   cy="88"
                                   r="1.66667"
                                   transform="rotate(180 75.6654 88)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="88"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 88)"
                                   
                                   />
                                <circle
                                   cx="75.6654"
                                   cy="73.3333"
                                   r="1.66667"
                                   transform="rotate(180 75.6654 73.3333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="73.3333"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 73.3333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="75.6654"
                                   cy="45"
                                   r="1.66667"
                                   transform="rotate(180 75.6654 45)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="45"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 45)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="75.6654"
                                   cy="16"
                                   r="1.66667"
                                   transform="rotate(180 75.6654 16)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="16"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 16)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="75.6654"
                                   cy="59"
                                   r="1.66667"
                                   transform="rotate(180 75.6654 59)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="59"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 59)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="75.6654"
                                   cy="30.6666"
                                   r="1.66667"
                                   transform="rotate(180 75.6654 30.6666)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="30.6666"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 30.6666)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="75.6654"
                                   cy="1.66665"
                                   r="1.66667"
                                   transform="rotate(180 75.6654 1.66665)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="31.9993"
                                   cy="1.66665"
                                   r="1.66667"
                                   transform="rotate(180 31.9993 1.66665)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="60.9993"
                                   cy="132"
                                   r="1.66667"
                                   transform="rotate(180 60.9993 132)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="132"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 132)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="60.9993"
                                   cy="117.333"
                                   r="1.66667"
                                   transform="rotate(180 60.9993 117.333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="117.333"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 117.333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="60.9993"
                                   cy="102.667"
                                   r="1.66667"
                                   transform="rotate(180 60.9993 102.667)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="102.667"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 102.667)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="60.9993"
                                   cy="88"
                                   r="1.66667"
                                   transform="rotate(180 60.9993 88)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="88"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 88)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="60.9993"
                                   cy="73.3333"
                                   r="1.66667"
                                   transform="rotate(180 60.9993 73.3333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="73.3333"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 73.3333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="60.9993"
                                   cy="45"
                                   r="1.66667"
                                   transform="rotate(180 60.9993 45)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="45"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 45)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="60.9993"
                                   cy="16"
                                   r="1.66667"
                                   transform="rotate(180 60.9993 16)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="16"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 16)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="60.9993"
                                   cy="59"
                                   r="1.66667"
                                   transform="rotate(180 60.9993 59)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="59"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 59)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="60.9993"
                                   cy="30.6666"
                                   r="1.66667"
                                   transform="rotate(180 60.9993 30.6666)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="30.6666"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 30.6666)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="60.9993"
                                   cy="1.66665"
                                   r="1.66667"
                                   transform="rotate(180 60.9993 1.66665)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="17.3333"
                                   cy="1.66665"
                                   r="1.66667"
                                   transform="rotate(180 17.3333 1.66665)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="46.3333"
                                   cy="132"
                                   r="1.66667"
                                   transform="rotate(180 46.3333 132)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="132"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 132)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="46.3333"
                                   cy="117.333"
                                   r="1.66667"
                                   transform="rotate(180 46.3333 117.333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="117.333"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 117.333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="46.3333"
                                   cy="102.667"
                                   r="1.66667"
                                   transform="rotate(180 46.3333 102.667)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="102.667"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 102.667)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="46.3333"
                                   cy="88"
                                   r="1.66667"
                                   transform="rotate(180 46.3333 88)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="88"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 88)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="46.3333"
                                   cy="73.3333"
                                   r="1.66667"
                                   transform="rotate(180 46.3333 73.3333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="73.3333"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 73.3333)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="46.3333"
                                   cy="45"
                                   r="1.66667"
                                   transform="rotate(180 46.3333 45)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="45"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 45)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="46.3333"
                                   cy="16"
                                   r="1.66667"
                                   transform="rotate(180 46.3333 16)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="16"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 16)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="46.3333"
                                   cy="59"
                                   r="1.66667"
                                   transform="rotate(180 46.3333 59)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="59"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 59)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="46.3333"
                                   cy="30.6666"
                                   r="1.66667"
                                   transform="rotate(180 46.3333 30.6666)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="30.6666"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 30.6666)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="46.3333"
                                   cy="1.66665"
                                   r="1.66667"
                                   transform="rotate(180 46.3333 1.66665)"
                                   fill="#000"
                                   />
                                <circle
                                   cx="2.66536"
                                   cy="1.66665"
                                   r="1.66667"
                                   transform="rotate(180 2.66536 1.66665)"
                                   fill="#000"
                                   />
                             </svg>
                          </span>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
           
        </section>
        <div className="my_shape2">
              <img className="svg" src="img/svg/paper2.svg" alt="" />
            </div>
       </>
    );
}