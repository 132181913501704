import React from "react";


export default function Header({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
    return (
      
      <>
      <nav className="flex flex-wrap items-center justify-between px-2 py-3 bg-black/80 backdrop-blur-md shadow-md w-full mb-3 fixed left-0 right-0 top-0 z-10 ">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <div className="logo-wrap">
                <a className="logo-white" href="#home">
                     <span className="logo-text" href="#home">Eric Williams</span>
                </a>
            </div>
            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1  bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex bg-black bg-opacity-70" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-white hover:text-orange"
                  href="#home"
                >Home</a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-white hover:text-orange"
                  href="#about"
                >About</a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-white hover:text-orange"
                  href="#portfolio"
                >Portfolio</a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-white hover:text-orange"
                  href="#contact"
                >Contact</a>
              </li>
              
            </ul>
          </div>
        </div>
      </nav>
    </>
      
    );
}