import React, { useEffect } from "react";
import AOS from "aos";
import Header from './components/Header'
import Hero from './components/Hero';
import About from "./components/About";
import Portfolio from "./components/Portfolio";
import './App.css';
import 'aos/dist/aos.css';
import Contact from "./components/Contact";
import Services from "./components/Services";
import Footer from "./components/Footer";



const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    
    <div className="ew-wrap">
      <Header/>
      <Hero/>
      <About/>
      <Services/>
      <Portfolio/>
      <Contact/>
      <Footer/>
  </div>
  
  );

}

export default App;
