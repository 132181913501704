import React, { useEffect, useState } from "react";
import sanityClient from "../client";


const Portfolio = () => {
  const [projectData, setProjectData] = useState(null);

useEffect(() => {
  sanityClient
    .fetch(
      `*[_type == "project"]{
    title,
    mainImage{
      asset->{
        _id,
        url
      },
      alt
    },
    date,
    place,
    description,
    projectType,
    link,
    tags
  }`
    )
    .then((data) => setProjectData(data))
    .catch(console.error);
}, []);
  return (
    <div className="ew_section" id="portfolio">
      
        <div className="ew_portfolio">
          <div className="container">
            <div className="grid grid-cols-1 gap-4 p-16">
              <div data-aos="fade-right" data-aos-duration="1500" >
                    <span className="text-xl font-extrabold tracking-tight text-gray-900 sm:text-2xl">Portfolio</span>
                    <h3 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Web Portfolio</h3>
              </div>

                  <div>
                    
                      < div className="grid sm:grid-cols-1 lg:grid-cols-3 gap-4 " >
                        {projectData && projectData.map((project, i) => (
                            <div data-aos="fade-right" data-aos-duration="1500" className="" >
                                <div className="flex justify-between items-center ml-4">
                                    <div className="bg-orange text-white bg-opacity-95 shadow px-2 py-1 flex items-center font-bold text-xs rounded">{project.projectType}</div>
                                    <div className="bg-orange w-10 h-12 shadow flex flex-col-reverse p-2 text-center font-bold text-white rounded-b-full"><i class="fa-solid fa-code"></i></div>
                                </div>
                                <div className="bg-opacity-95 shadow-md  p-4 flex flex-col mb-8">
                                <img
                                      src={project.mainImage.asset.url}
                                      alt={""}
                                    />
                                    <a href={project.link}
                                       rel="noopener noreferrer"
                                      target="_blank" > 
                                      <h3 className="text-xl font-bold mt-5">{project.title}</h3>
                                    </a>
                                </div>
                            </div>
                          ))}
                      </div>
                    
                </div>
               
              
              
            </div>
    
                
            
          </div>
        </div>
      
    </div>
  );
};

export default Portfolio;
