import React from "react";



function Services(){
    return(
       <>
<div id="services" class="section relative pt-20 pb-8 md:pt-16 md:pb-0 bg-black">
    <div class="container xl:max-w-6xl mx-auto px-4">
        <div data-aos="fade-right" data-aos-duration="1500" class="flex flex-wrap flex-row -mx-4 text-center">
            <div class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp" data-wow-duration="1s" >
               
                <div class=" py-8 px-12 mb-12 bg-transparent hover:border-orange border border-white transform transition duration-300 ease-in-out hover:-translate-y-2">
                    <div class="inline-block mb-4">
                        <span class="skills seo"><img class="svg" src="img/svg/skills/seo.svg" alt="" /></span>
                    </div>
                    <h3 class="text-lg leading-normal mb-2 uppercase font-semibold text-white">Search Engine Optimization</h3>
                    <p class="text-gray-500">Nowadays, just a website is not enough. SEO is a big deal. </p>
                </div>
                
            </div>
            <div class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s" >
                
                <div class="py-8 px-12 mb-12 bg-transparent hover:border-orange border border-white transform transition duration-300 ease-in-out hover:-translate-y-2">
                    <div class="inline-block mb-4">
                        <span class="skills code"><img class="svg" src="img/svg/skills/dev.svg" alt="" /></span> 
                    </div>
                    <h3 class="text-lg leading-normal uppercase mb-2 font-semibold text-white">Web Development</h3>
                    <p class="text-gray-500"> Can build a site from scratch or customize an existing one for your needs.</p>
                </div>
              
            </div>
            <div class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s" >
                
                <div class="button py-8 px-12 mb-12 bg-transparent border hover:border-orange border-white transform transition duration-300 ease-in-out hover:-translate-y-2">
                    <div class="inline-block  mb-4">
                    <span class="skills design "><img class="svg" src="img/svg/skills/xd.svg" alt="" /></span>

                        
                    </div>
                    <h3 class="text-lg leading-normal mb-2 font-semibold text-white">Web Design</h3>
                    <p class="text-gray-500">I want to make your website stand out and get you more customers! </p>
                </div>
            </div>
        </div>
        
    </div>
    <div className="my_shape">
              <img className="svg" src="img/svg/paper.svg" alt="" />
            </div>
</div>

            </>
    );
}

export default Services;