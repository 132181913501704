import React from "react";
import TypeAnimation from 'react-type-animation';



function Hero() {
    return(
        <div className="ew_hero " id="home">
          <div className="background">
            <div
              className="image"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "img/hero/profile.png"
                })`,
              }}
            ></div>
          </div>
          {/* End .background */}
         
          <div data-aos="fade-left" data-aos-duration="1200" className="container">
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 content">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
                <span   className="block xl:inline">Eric</span>{' '}
                <span  className="block text-orange xl:inline">Williams</span>
              </h1>
              <span className= "text-2xl tracking-tight font-extrabold text-slate-50 sm:text-3xl md:text-4xl">
                <TypeAnimation
        cursor={true}
        sequence={[
          'Web Developer.',
          3000,
          'Web Designer.',
          3000,
        ]}
        wrapper="h2"
        repeat={Infinity}
       />
              </span>
              <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">I'm a self-proclaimed "web developer," and this is what you'll find when browsing through the rest of my site: all things related to building websites for clients across America!</p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="#contact"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange hover:bg-orange md:py-4 md:text-lg md:px-10"
                  >
                    Contact Me
                  </a>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <a
                    href="#portfolio"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-500 hover:bg-orange md:py-4 md:text-lg md:px-10"
                  >
                    View Portfolio
                  </a>
                </div>
              </div>
            </div>
          </main>
            {/* End content */}
          </div>
          {/* End .container */}
           <div className="my_shape">
              <img className="svg" src="img/svg/paper.svg" alt="" />
            </div>
        </div>
    );
}

export default Hero;